import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { TableChartOutlined } from '@mui/icons-material';
// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Typography } from '@mui/material';
// import { Tabs, Tab } from '@mui/material';
import { ValidationContext } from '../../../../DataStore';
import { DataGridTable, TableErrorDetails } from './components';

// import { VizMermaid } from './components';
// import mermaidExample from './components/VizMermaid/mermaid_example';

export const TableDetails = ({ fileName, storagePath }) => {
  const { validation } = useContext(ValidationContext);
  const [tableValidation, setTableValidation] = useState({ status: null, details: [] });

  // const [tabValue, setTabValue] = React.useState(0);
  // const handleTabChange = (_event, newValue) => {
  //   setTabValue(newValue);
  // };

  useEffect(() => {
    setTableValidation({ ...validation.tables[fileName] });
  }, [validation, fileName]);
  return (
    <Box sx={{ gap: 2, p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5">{fileName.split('.').slice(0, -1).join('.')}</Typography>
      <TableErrorDetails validation={tableValidation} />
      {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}></Typography>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="Table Details tabs">
          <Tab icon={<TableChartOutlined />} label="Table" iconPosition="start" />
          <Tab icon={<VisibilityOutlinedIcon />} label="Visualisation" iconPosition="start" />
        </Tabs>
      </Box> */}

      {/* TAB 0: table view Contents */}
      {/* {tabValue === 0 && ( */}
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '160px' }}>
        <DataGridTable storagePath={storagePath} />
      </Box>
      {/* // )} */}
      {/* TAB 1: visualization view Contents */}
      {/* {tabValue === 1 && (
        <Box>
          {fileName === 'HierarchieDesEquipements.csv' ? (
            <VizMermaid chart={mermaidExample}></VizMermaid>
          ) : (
            <Box>{fileName}: Pas de visualisation implémentée pour ce type de table.</Box>
          )}
        </Box>
      )} */}
    </Box>
  );
};

TableDetails.propTypes = {
  fileName: PropTypes.string.isRequired,
  storagePath: PropTypes.string.isRequired,
};
