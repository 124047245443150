import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from '../../../../../../services/config/Api';
import RunnerRunService from '../../../../../../services/runnerRun/RunnerRunService';
import { useSetApplicationErrorMessage } from '../../../../../../state/hooks/ApplicationHooks';
import { useCurrentDataset, useDeleteDataset } from '../../../../../../state/hooks/DatasetHooks';
import { useOrganizationId } from '../../../../../../state/hooks/OrganizationHooks';
import { useWorkspaceId } from '../../../../../../state/hooks/WorkspaceHooks';
import { useDataStoreListAllFiles } from '../../../../DataStoreHooks';

export const useDownloadRunLogs = () => {
  const organizationId = useOrganizationId();
  const workspaceId = useWorkspaceId();
  return useCallback(
    (runnerId, runnerRunId) => RunnerRunService.downloadLogsFile(organizationId, workspaceId, runnerId, runnerRunId),
    [organizationId, workspaceId]
  );
};

export const useDeleteDataStore = () => {
  const { t } = useTranslation();
  const setApplicationErrorMessage = useSetApplicationErrorMessage();
  const organizationId = useOrganizationId();
  const workspaceId = useWorkspaceId();
  const dataset = useCurrentDataset();
  const listAllFiles = useDataStoreListAllFiles();
  const deleteDataset = useDeleteDataset();
  const datasetStorageRoot = useMemo(() => `datastore/${dataset.id}/`, [dataset]);

  return useCallback(
    async () => {
      try {
        await listAllFiles().then(async (data) => {
          await Promise.all(
            data.data
              .filter((file) => file.fileName.startsWith(datasetStorageRoot))
              .map((file) => Api.Workspaces.deleteWorkspaceFile(organizationId, workspaceId, file.fileName))
          );
        });
        await Promise.all(
          Object.values(dataset.associatedRunners).map((runner) =>
            Api.Runners.deleteRunner(organizationId, workspaceId, runner.id)
          )
        );
        deleteDataset(dataset.id);
      } catch (error) {
        console.error(error);
        setApplicationErrorMessage(
          error,
          t('commoncomponents.datastore.errors.delete', 'A problem occurred during data store delete.')
        );
      }
    },
    [
      listAllFiles,
      datasetStorageRoot,
      organizationId,
      workspaceId,
      dataset,
      deleteDataset,
      t,
      setApplicationErrorMessage,
    ],
    t
  );
};
