import { useCallback, useMemo } from 'react';
import { useWorkspaceMainDatasets } from '../../../../hooks/WorkspaceDatasetsHooks';
import { ACL_PERMISSIONS } from '../../../../services/config/accessControl';
import { useCurrentDataset, useSelectDataset } from '../../../../state/hooks/DatasetHooks';
import { useOrganizationData } from '../../../../state/hooks/OrganizationHooks';
import { useUpdateDatastoreAssociatedRunners } from '../../../../state/hooks/RunnerHooks';

export const useDataStoreList = () => {
  const userPermissionsInCurrentOrganization = useOrganizationData()?.security?.currentUserPermissions ?? [];
  const datasets = useWorkspaceMainDatasets();
  const selectDataset = useSelectDataset();
  const updateDataStoreAssociatedRunner = useUpdateDatastoreAssociatedRunners();
  const selectDatasetAndRunner = useCallback(
    (dataset) => {
      selectDataset(dataset);
      updateDataStoreAssociatedRunner(dataset.id);
    },
    [selectDataset, updateDataStoreAssociatedRunner]
  );
  const currentDataset = useCurrentDataset();

  const visibleDataStoreItems = useMemo(() => {
    const visibleDatasets = datasets.filter(
      (dataset) =>
        dataset?.security?.currentUserPermissions?.includes(ACL_PERMISSIONS.DATASET.READ) &&
        dataset?.tags?.includes('datastore')
    );
    visibleDatasets.sort((d1, d2) => (d1.name?.toLowerCase() >= d2.name?.toLowerCase() ? 1 : -1));
    return visibleDatasets;
  }, [datasets]);

  return {
    userPermissionsInCurrentOrganization,
    dataStoreItems: visibleDataStoreItems,
    currentDataset,
    selectDatasetAndRunner,
  };
};
