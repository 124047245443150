import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { AgGridUtils } from '@cosmotech/core';
import { useDataStoreFileDownload } from '../../../../../../DataStoreHooks';

export const DataGridTable = ({ storagePath }) => {
  const downloadFile = useDataStoreFileDownload();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tableData, setTableData] = useState({ rows: [], cols: [] });
  useEffect(() => {
    setLoading(true);
    downloadFile(storagePath)
      .then(({ data: fileContent }) => {
        const header = fileContent.substring(0, fileContent.indexOf('\n')).split(',');
        const nestedCols = header.map((colName) => {
          return { field: colName, type: 'string', acceptsEmptyFields: true, flex: 1, minWidth: 150 };
        });
        const { rows, cols } = AgGridUtils.fromCSV(fileContent, true, nestedCols);
        if (rows === undefined || cols === undefined) {
          setError(true);
        } else {
          setError(false);
          rows.forEach((row, index) => {
            if (row.id === undefined) row.id = index;
          });
          setTableData({ rows, cols });
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        console.error(error);
        setLoading(false);
      });
  }, [storagePath, downloadFile, error]);

  return (
    <>
      {loading ? (
        <CircularProgress sx={{ mx: 'auto', mt: 2, display: 'block' }} />
      ) : error ? (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6" color="error">
            Erreur lors du chargement de la table
          </Typography>
          <Typography color="error">Veuillez vérifier que le format de la table est correct puis réessayez.</Typography>
        </Box>
      ) : (
        <DataGrid
          columns={tableData.cols}
          rows={tableData.rows}
          sx={{ backgroundColor: (theme) => theme.palette.microsoft.main }}
        />
      )}
    </>
  );
};

DataGridTable.propTypes = {
  storagePath: PropTypes.string.isRequired,
};
