import React, { useCallback, useState, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  FileDownload,
  IntegrationInstructionsOutlined,
  FactCheckOutlined,
  DeleteForeverOutlined,
  MoreVert,
} from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { FileBlobUtils } from '@cosmotech/core';
import { FadingTooltip } from '@cosmotech/ui';
import { TwoActionsDialogService } from '../../../../../../services/twoActionsDialog/twoActionsDialogService';
import { useSetApplicationErrorMessage } from '../../../../../../state/hooks/ApplicationHooks';
import { useCurrentDataset } from '../../../../../../state/hooks/DatasetHooks';
import { ValidationContext } from '../../../../DataStore';
import { useDataStoreFileDownload } from '../../../../DataStoreHooks';
import { useDeleteDataStore, useDownloadRunLogs } from './DataStoreOptionsHooks';

const LOG_BUTTON_LABEL_MAP = {
  validation: 'vérification',
  push: 'création dataset',
};

const DownloadLogsButton = ({ handleClose, runnerName }) => {
  const downloadRunLogs = useDownloadRunLogs();
  const dataset = useCurrentDataset();
  const { id: runnerId, lastRunId: runnerRunId } = dataset.associatedRunners?.[runnerName] ?? {};
  const handleDownloadLogs = useCallback(() => {
    downloadRunLogs(runnerId, runnerRunId);
    handleClose();
  }, [runnerId, runnerRunId, downloadRunLogs, handleClose]);
  return (
    <MenuItem onClick={handleDownloadLogs} disabled={!runnerId || !runnerRunId}>
      <IntegrationInstructionsOutlined fontSize="large" />
      (debug) Logs {LOG_BUTTON_LABEL_MAP[runnerName]}
    </MenuItem>
  );
};

DownloadLogsButton.propTypes = {
  handleClose: PropTypes.func.isRequired,
  runnerName: PropTypes.string,
};

const DownloadDatastoreZip = ({ handleClose }) => {
  const { t } = useTranslation();
  const setApplicationErrorMessage = useSetApplicationErrorMessage();
  const downloadFile = useDataStoreFileDownload();
  const { storageRoot, isArchiveAvailable } = useContext(ValidationContext);
  const { name: datasetName } = useCurrentDataset();
  const handleDownloadDatastoreZip = useCallback(() => {
    downloadFile(`${storageRoot}datastore.zip`, { responseType: 'blob' })
      .then((data) => {
        FileBlobUtils.downloadFileFromData(data.data, `${datasetName.replace(' ', '_')}.zip`);
      })
      .catch((error) => {
        console.error(error);
        setApplicationErrorMessage(
          error,
          t('commoncomponents.datastore.errors.downloadZIPFile', 'A problem occurred when downloading ZIP file.')
        );
      });
    handleClose();
  }, [storageRoot, downloadFile, handleClose, datasetName, t, setApplicationErrorMessage]);
  return (
    <MenuItem onClick={handleDownloadDatastoreZip} disabled={!isArchiveAvailable}>
      <FileDownload fontSize="large" />
      Télécharger au format ZIP
    </MenuItem>
  );
};

DownloadDatastoreZip.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

const DownloadReportButton = ({ handleClose }) => {
  const { t } = useTranslation();
  const setApplicationErrorMessage = useSetApplicationErrorMessage();
  const { isReportAvailable, storageRoot } = useContext(ValidationContext);
  const downloadFile = useDataStoreFileDownload();
  const { name: datasetName } = useCurrentDataset();
  const handleDownloadReport = useCallback(() => {
    downloadFile(`${storageRoot}validation_report.txt`)
      .then((data) => {
        FileBlobUtils.downloadFileFromData(data.data, `${datasetName.replace(' ', '_')}_validation_report.txt`);
      })
      .catch((error) => {
        console.error(error);
        setApplicationErrorMessage(
          error,
          t(
            'commoncomponents.datastore.errors.downloadValidationReport',
            'A problem occurred when downloading validation report.'
          )
        );
      });
    handleClose();
  }, [handleClose, storageRoot, downloadFile, datasetName, t, setApplicationErrorMessage]);
  return (
    <MenuItem onClick={handleDownloadReport} disabled={!isReportAvailable}>
      <FactCheckOutlined fontSize="large" />
      Télécharger le rapport de validation
    </MenuItem>
  );
};

DownloadReportButton.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

const DeleteDataStoreButton = ({ handleClose }) => {
  const dataset = useCurrentDataset();
  const { t } = useTranslation();
  const deleteDataStore = useDeleteDataStore();
  const handleDeleteDataStore = useCallback(() => {
    deleteDataStore();
    handleClose();
  }, [deleteDataStore, handleClose]);
  const askConfirmationToDeleteDialog = useCallback(
    async (event) => {
      event.stopPropagation();
      const dialogProps = {
        id: 'delete-datastore',
        component: 'div',
        labels: {
          title: t('commoncomponents.datastore.dialogs.delete.title', 'Delete datastore?'),
          body: (
            <Trans
              i18nKey="commoncomponents.datastore.dialogs.delete.body"
              defaultValue="Do you really want to delete <i>{{datasetName}}</i>?
              This action is irreversible"
              values={{ datasetName: dataset?.name }}
              shouldUnescape={true}
            />
          ),
          button1: t('commoncomponents.datasetmanager.dialogs.cancel', 'Cancel'),
          button2: t('commoncomponents.datasetmanager.dialogs.delete.confirmButton', 'Delete'),
        },
        button2Props: {
          color: 'error',
        },
      };
      const result = await TwoActionsDialogService.openDialog(dialogProps);
      if (result === 2) {
        handleDeleteDataStore(dataset?.id);
      }
    },
    [t, handleDeleteDataStore, dataset]
  );
  return (
    <MenuItem onClick={askConfirmationToDeleteDialog} sx={{ color: 'red' }}>
      <DeleteForeverOutlined fontSize="large" color="inherit" />
      Supprimer le magasin de données
    </MenuItem>
  );
};

DeleteDataStoreButton.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export const DataStoreOptions = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <FadingTooltip title="Options supplémentaires.">
        <IconButton color="inherit" onClick={handleClick} sx={{ mx: 'auto' }}>
          <MoreVert fontSize="medium" />
        </IconButton>
      </FadingTooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <DownloadDatastoreZip handleClose={handleClose} />
        <DownloadReportButton handleClose={handleClose} />
        <DeleteDataStoreButton handleClose={handleClose} />
        <DownloadLogsButton handleClose={handleClose} runnerName="validation" />
        <DownloadLogsButton handleClose={handleClose} runnerName="push" />
      </Menu>
    </>
  );
};
