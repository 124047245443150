import React from 'react';
import PropTypes from 'prop-types';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, AccordionDetails, List, ListItem, Typography, Card } from '@mui/material';
import { FileStatusIcon } from '../../../FileStatusIcon';

export const TableErrorDetails = ({ validation }) => {
  const getStatusMessage = (validation) => {
    return (
      <>
        <FileStatusIcon status={validation.status} />
        {
          {
            error: <Typography color="error.main">{validation.details?.length} erreur(s) de validation</Typography>,
            valid: <Typography color="success.main">Table valide</Typography>,
            modified: <Typography color="goldenrod">Cette table a été modifiée et doit être validée</Typography>,
            draft: <Typography>Cette table n&#39;a pas encore été validée</Typography>,
          }[validation.status || 'draft']
        }
      </>
    );
  };
  return (
    <>
      {validation.details?.length > 0 ? (
        <Accordion sx={{ mv: 1 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>{getStatusMessage(validation)}</AccordionSummary>
          <AccordionDetails>
            <List sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
              {validation.details.map((detail) => (
                <ListItem key={detail} sx={{ display: 'list-item' }}>
                  {typeof detail === 'string' ? detail : JSON.stringify(detail)}
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ) : (
        <Card sx={{ display: 'flex', p: 1.5, overflow: 'unset' }}>{getStatusMessage(validation)}</Card>
      )}
    </>
  );
};

TableErrorDetails.propTypes = {
  validation: PropTypes.object.isRequired,
};
