import React, { useMemo, useState } from 'react';
import { Grid, Switch, Typography } from '@mui/material';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useGestionEquipements } from './hooks/GestionEquipementsHook';

const GestionEquipements = () => {
  const [showHealthDistribution, setShowHealthDistribution] = useState(false);
  const { ageConcernedEquipmentsByFamily } = useGestionEquipements();

  const chartData = useMemo(() => {
    if (ageConcernedEquipmentsByFamily?.length === 0) return [];
    if (showHealthDistribution) {
      const healthCounts = {};
      let maxHealth = 0;
      ageConcernedEquipmentsByFamily?.forEach((item) => {
        const health = parseInt(item.AgeApparent);
        healthCounts[health] = (healthCounts[health] || 0) + 1;
        if (health > maxHealth) maxHealth = health;
      });
      return Array.from({ length: maxHealth + 1 }, (_, health) => ({
        healthStatus: health.toString(),
        volume: healthCounts[health] || 0,
      }));
    } else {
      const equipmentAges = ageConcernedEquipmentsByFamily?.map((item) => parseInt(item['CustomDataNumber.AgeReel']));
      const ageCounts = {};
      let maxAge = 0;
      equipmentAges.forEach((age) => {
        ageCounts[age] = (ageCounts[age] || 0) + 1;
        if (age > maxAge) maxAge = age;
      });
      return Array.from({ length: maxAge + 1 }, (_, age) => ({
        age: age.toString(),
        volume: ageCounts[age] || 0,
      }));
    }
  }, [ageConcernedEquipmentsByFamily, showHealthDistribution]);
  if (chartData?.length === 0)
    return (
      <Typography variant="h6" align="center" style={{ marginTop: '20px' }}>
        Aucune donnée disponible pour la famille sélectionnée.
      </Typography>
    );

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        {/* Switch pour changer entre âge et état de santé */}
        <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
          <span>Âge</span>
          <Switch
            checked={showHealthDistribution}
            onChange={(e) => setShowHealthDistribution(e.target.checked)}
            color="primary"
            inputProps={{ 'aria-label': 'switch between age and health distribution' }}
          />
          <span>État de santé</span>
        </Grid>
      </Grid>
      <ResponsiveContainer width="100%" height={400} style={{ marginTop: '20px' }}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={showHealthDistribution ? 'healthStatus' : 'age'}
            label={{
              value: showHealthDistribution ? 'État de santé' : 'Âge des équipements',
              position: 'insideBottom',
              offset: -5,
            }}
          />
          <YAxis label={{ value: "Nombre d'équipements", angle: -90, position: 'insideLeft' }} allowDecimals={false} />
          <Tooltip />
          <Legend />
          <Bar dataKey="volume" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GestionEquipements;
