// Fonction de survie pour la loi de Weibull
export function rWeibull(t, shape, scale) {
  return Math.exp(-Math.pow(t / scale, shape));
}

// Calcul du paramètre scale (échelle) pour Weibull à partir d'un point
export function resolveScaleWeibull(t1, R1, shape) {
  return t1 / Math.pow(-Math.log(R1), 1 / shape);
}

// Calcul du paramètre shape (forme) pour Weibull à partir de deux points
export function resolveShapeWeibull(t1, t2, R1, R2) {
  return Math.log(Math.log(R1) / Math.log(R2)) / Math.log(t1 / t2);
}

// Fonction de survie pour la loi de Gompertz
export function rGompertz(t, shape, scale) {
  return Math.exp(-shape * (Math.exp(scale * t) - 1));
}

// Évaluation de b pour l'optimisation
export function evalB(b, t1, t2, R1, R2) {
  const A = Math.exp(b * t1) - 1;
  const B = Math.exp(b * t2) - 1;
  const C = Math.log(R1);
  const D = Math.log(R2);

  return b === 0 ? t1 / t2 - C / D : A / B - C / D;
}

// Évaluation de la dérivée de b pour la recherche de solution
export function evalBPrime(b, t1, t2) {
  if (b === 0) return 0.5 * (t1 / t2) * (t1 - t2);
  const A = Math.exp(b * t1) - 1;
  const APrime = t1 * Math.exp(b * t1);
  const B = Math.exp(b * t2) - 1;
  const BPrime = t2 * Math.exp(b * t2);

  return (APrime * B - A * BPrime) / Math.pow(B, 2);
}

// Résolution de la valeur de scale (b) en utilisant la méthode de Newton-Raphson
export function resolveScale(t1, t2, R1, R2) {
  if (t2 <= t1) throw new Error('t1 must be smaller than t2');
  if (t1 / t2 <= Math.log(R1) / Math.log(R2)) throw new Error('No solution with a positive scale can be found');

  const tol = 1e-6;
  const nIterMax = 1000;
  let b = 0.01; // Initialisation avec une petite valeur pour éviter la division par zéro

  for (let i = 0; i < nIterMax; i++) {
    const fB = evalB(b, t1, t2, R1, R2);
    if (Math.abs(fB) < tol) break;

    const fBPrime = evalBPrime(b, t1, t2);
    if (Math.abs(fBPrime) < tol) throw new Error('Cannot find a solution due to too small derivative value.');

    b = b - fB / fBPrime;
  }

  return b;
}

// Résolution de la forme (shape) pour Gompertz
export function resolveShape(b, t1, R1) {
  return -Math.log(R1) / (Math.exp(b * t1) - 1);
}
