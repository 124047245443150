import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Collapse, IconButton, TextField, Grid, Autocomplete, CircularProgress, Typography } from '@mui/material';
import { INGESTION_STATUS } from '../../services/config/ApiConstants';
import { useEquipementWizardHook } from './EquipementWizardHook';
import FamilleEquipements from './steps/FamilleEquipements';
import GestionEquipements from './steps/GestionEquipements';
import VecteurDonnees from './steps/VecteurDonnees';

const EquipementWizard = ({ context }) => {
  const [openFamille, setOpenFamille] = useState(true);
  const [openGestion, setOpenGestion] = useState(false);
  const [openVecteur, setOpenVecteur] = useState(false);
  const {
    status,
    agingLaws,
    addTechVar,
    familleEquipement,
    scenarioId,
    datasetId,
    doesDatasetExist,
    isDatasetTwingraph,
    datasetIngestionStatus,
  } = useEquipementWizardHook();

  const familleEquipementList = useMemo(() => {
    return agingLaws?.FamilleEquipement?.map((fe) => fe.Identifiant) ?? [];
  }, [agingLaws]);
  if (!datasetId)
    return (
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Typography>Aucun dataset n&apos;est associé au scénario choisi</Typography>
      </Grid>
    );
  if (!doesDatasetExist)
    return (
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Typography>Le dataset associé au scénario n&apos;a pas pu être trouvé</Typography>
      </Grid>
    );
  if (!isDatasetTwingraph)
    return (
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Typography>Le dataset associé au scénario n&apos;est pas du type twingraph</Typography>
      </Grid>
    );
  if (datasetIngestionStatus !== INGESTION_STATUS.SUCCESS)
    return (
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Typography>
          Le dataset associé au scénario n&apos;est pas prêt, son status est {datasetIngestionStatus}
        </Typography>
      </Grid>
    );
  return status === 'LOADING' ? (
    <CircularProgress />
  ) : (
    <div style={{ padding: '20px' }}>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={familleEquipementList}
            value={familleEquipementList?.find((option) => option === familleEquipement) || null}
            onChange={(event, newValue) => {
              addTechVar({ familleEquipement: newValue }, scenarioId);
            }}
            renderInput={(params) => <TextField {...params} label="Famille d'équipement" fullWidth />}
          />
        </Grid>
      </Grid>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h3>Gestion des familles d&#39;équipements</h3>
        <IconButton onClick={() => setOpenFamille(!openFamille)}>
          {openFamille ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </div>
      <Collapse in={openFamille}>
        <FamilleEquipements context={context} />
      </Collapse>

      <hr style={{ margin: '40px 0' }} />

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h3>Gestion des équipements</h3>
        <IconButton onClick={() => setOpenGestion(!openGestion)}>
          {openGestion ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </div>
      <Collapse in={openGestion}>{<GestionEquipements />}</Collapse>

      <hr style={{ margin: '40px 0' }} />

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h3>Vecteurs de données</h3>
        <IconButton onClick={() => setOpenVecteur(!openVecteur)}>
          {openVecteur ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </div>
      <Collapse in={openVecteur}>
        <VecteurDonnees context={context} />
      </Collapse>
    </div>
  );
};

EquipementWizard.propTypes = {
  context: PropTypes.object,
};

export default EquipementWizard;
