import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Switch,
  TextField,
} from '@mui/material';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { UPLOAD_FILE_STATUS_KEY } from '@cosmotech/ui';
import { useFamilleEquipements } from './hooks/FamilleEquipementsHook';
import {
  resolveScale,
  resolveScaleWeibull,
  resolveShape,
  resolveShapeWeibull,
  rGompertz,
  rWeibull,
} from './typeLoi.utils';

const FamilleEquipements = ({ context }) => {
  const [showFailureChart, setShowFailureChart] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [points, setPoints] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const { agingLaws, equipmentFamily } = useFamilleEquipements();

  const setNewFamilleEquipementValue = (value, changedValues) => {
    return value.map((fe) => {
      if (fe.Identifiant === equipmentFamily) {
        return { ...fe, ...changedValues };
      }
      return fe;
    });
  };

  const updateCurveParameters = (modeFiabilite) => {
    if (!points) return;

    const t1 = parseFloat(points.t1);
    const t2 = parseFloat(points.t2);
    const R1 = parseFloat(points.R1);
    const R2 = parseFloat(points.R2);

    if (isNaN(t1) || isNaN(t2) || isNaN(R1) || isNaN(R2)) {
      setErrorMessage('Veuillez entrer des valeurs numériques valides pour t1, t2, R1, et R2.');
      return;
    }

    if (t1 <= 0 || t2 <= 0 || t2 <= t1) {
      setErrorMessage('Assurez-vous que t1 et t2 sont positifs avec t2 > t1.');
      return;
    }

    if (R1 <= 0 || R1 >= 1 || R2 <= 0 || R2 >= 1) {
      setErrorMessage('R1 et R2 doivent être des nombres entre 0 et 1 (exclus).');
      return;
    }

    setErrorMessage('');

    try {
      if (modeFiabilite === 'Gompertz') {
        const b = resolveScale(t1, t2, R1, R2);
        const eta = resolveShape(b, t1, R1);
        return { Echelle: b, Forme: eta };
      } else if (modeFiabilite === 'Weibull') {
        const eta = resolveShapeWeibull(t1, t2, R1, R2);
        const lambda = resolveScaleWeibull(t1, R1, eta);
        return { Echelle: lambda, Forme: eta };
      }
    } catch (error) {
      console.error('Erreur dans le calcul des paramètres :', error);
      setErrorMessage('Une erreur est survenue lors du calcul des paramètres. Veuillez vérifier vos entrées.');
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setPoints(null);
  };

  const handleApplyChanges = () => {
    setOpenDialog(false);
  };

  // Fonction pour mettre à jour les points en tant que chaînes de caractères
  const updatePoint = (key, value) => {
    setPoints((prevPoints) => ({
      ...(prevPoints || {}),
      [key]: value,
    }));
  };

  // Récupérer la valeur du point ou une chaîne vide
  const getPointValue = (key) => {
    return points && points[key] !== undefined ? points[key] : '';
  };

  const generateSurvivalData = (modeFiabilite, echelle, forme) => {
    const data = [];
    const tMax = Math.max(echelle * 2, 1); // Ajustement dynamique de tMax
    const step = 1;
    for (let t = 0; t <= tMax; t += step) {
      let R = 0;
      if (modeFiabilite === 'Gompertz') {
        R = rGompertz(t, forme, echelle);
      } else if (modeFiabilite === 'Weibull') {
        R = rWeibull(t, forme, echelle);
      }
      data.push({ t, R: showFailureChart ? 1 - R : R });
    }
    return data;
  };

  const defaultValue = {
    id: null,
    name: `FamilleEquipement.csv`,
    rows: agingLaws?.FamilleEquipement ?? [],
  };

  if (!equipmentFamily) return <span>Aucune donnée à afficher.</span>;

  return (
    <div>
      <Controller
        name="FamilleEquipement"
        defaultValue={defaultValue}
        render={({ field }) => {
          const { value, onChange } = field;
          return (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} marginTop={2}>
                  <TextField
                    select
                    label="Mode de Fiabilité"
                    disabled={!context.editMode}
                    value={value.rows?.find((fe) => fe.Identifiant === equipmentFamily)?.ModeFiabilite}
                    onChange={(e) => {
                      const newModeFiabilite = e.target.value;
                      const newValue = setNewFamilleEquipementValue(value.rows, {
                        ModeFiabilite: newModeFiabilite,
                      });
                      onChange({ ...value, status: UPLOAD_FILE_STATUS_KEY.READY_TO_UPLOAD, rows: newValue });
                    }}
                    fullWidth
                  >
                    <MenuItem value="Weibull">Weibull</MenuItem>
                    <MenuItem value="Gompertz">Gompertz</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Forme"
                    type="number"
                    disabled={!context.editMode}
                    value={value.rows?.find((fe) => fe.Identifiant === equipmentFamily)?.Forme}
                    onChange={(e) => {
                      const newForme = parseFloat(e.target.value);
                      const newValue = setNewFamilleEquipementValue(value.rows, {
                        Forme: newForme,
                      });
                      onChange({ ...value, status: UPLOAD_FILE_STATUS_KEY.READY_TO_UPLOAD, rows: newValue });
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Échelle"
                    type="number"
                    disabled={!context.editMode}
                    value={value.rows?.find((fe) => fe.Identifiant === equipmentFamily)?.Echelle}
                    onChange={(e) => {
                      const newEchelle = parseFloat(e.target.value);
                      const newValue = setNewFamilleEquipementValue(value.rows, {
                        Echelle: newEchelle,
                      });
                      onChange({ ...value, status: UPLOAD_FILE_STATUS_KEY.READY_TO_UPLOAD, rows: newValue });
                    }}
                    fullWidth
                  />
                </Grid>
                <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="sm">
                  <DialogTitle>Modifier les points de la courbe</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2} padding={1}>
                      <Grid item xs={6}>
                        <TextField
                          label="t1"
                          type="number"
                          inputProps={{ min: 0 }}
                          value={getPointValue('t1')}
                          onChange={(e) => updatePoint('t1', e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="R1"
                          type="number"
                          inputProps={{ step: 'any', min: 0, max: 1 }}
                          value={getPointValue('R1')}
                          onChange={(e) => updatePoint('R1', e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="t2"
                          type="number"
                          inputProps={{ min: 0 }}
                          value={getPointValue('t2')}
                          onChange={(e) => updatePoint('t2', e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="R2"
                          type="number"
                          inputProps={{ step: 'any', min: 0, max: 1 }}
                          value={getPointValue('R2')}
                          onChange={(e) => updatePoint('R2', e.target.value)}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                      Annuler
                    </Button>
                    <Button
                      onClick={() => {
                        const modeFiabilité = value.rows?.find(
                          (fe) => fe.Identifiant === equipmentFamily
                        )?.ModeFiabilite;
                        const newFormeAndEchelle = updateCurveParameters(modeFiabilité);
                        const newValue = setNewFamilleEquipementValue(value.rows, newFormeAndEchelle);
                        onChange({ ...value, status: UPLOAD_FILE_STATUS_KEY.READY_TO_UPLOAD, rows: newValue });
                        handleApplyChanges();
                      }}
                      color="primary"
                    >
                      Appliquer
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              {/* Commutateur pour la courbe de survie/panne */}
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                <span>Survie</span>
                <Switch
                  checked={showFailureChart}
                  onChange={(e) => setShowFailureChart(e.target.checked)}
                  color="primary"
                  inputProps={{ 'aria-label': 'switch between survival and failure mode' }}
                />
                <span>Panne</span>
              </div>

              {/* Afficher le message d'erreur si nécessaire */}
              {errorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>}

              {/* Affichage de la courbe */}
              <div style={{ marginTop: '30px' }}>
                <h4>
                  {showFailureChart
                    ? 'Visualisation de la courbe de probabilité de panne cumulée'
                    : 'Visualisation de la courbe de survie'}
                </h4>
                <ResponsiveContainer width="100%" height={400} style={{ marginTop: '20px' }}>
                  <LineChart
                    data={generateSurvivalData(
                      value.rows?.find((fe) => fe.Identifiant === equipmentFamily)?.ModeFiabilite,
                      value.rows?.find((fe) => fe.Identifiant === equipmentFamily)?.Echelle,
                      value.rows?.find((fe) => fe.Identifiant === equipmentFamily)?.Forme
                    )}
                    margin={{ top: 20, left: 0, right: 0, bottom: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="t" label={{ value: 'Temps (t)', position: 'insideBottomRight', offset: -5 }} />
                    <YAxis
                      domain={[0, 1]}
                      label={{
                        value: showFailureChart ? 'Probabilité de panne' : 'Probabilité de survie',
                        angle: -90,
                        offset: 10,
                        position: 'insideBottomLeft',
                      }}
                    />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="R"
                      stroke="#8884d8"
                      strokeWidth={3}
                      dot={false}
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>

              {/* Bouton pour éditer les paramètres de courbe */}
              <Button
                disabled={!context.editMode}
                variant="contained"
                color="primary"
                onClick={() => setOpenDialog(true)}
                style={{ marginTop: '20px' }}
              >
                Éditer les paramètres de courbes de survie
              </Button>
            </>
          );
        }}
      />
    </div>
  );
};

FamilleEquipements.propTypes = {
  context: PropTypes.object,
};

export default FamilleEquipements;
