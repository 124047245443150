import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from '../../services/config/Api';
import { useSetApplicationErrorMessage } from '../../state/hooks/ApplicationHooks';
import { useCurrentDataset } from '../../state/hooks/DatasetHooks';
import { useOrganizationId } from '../../state/hooks/OrganizationHooks';
import { useUpdateDatastoreAssociatedRunners } from '../../state/hooks/RunnerHooks';
import { useWorkspaceId } from '../../state/hooks/WorkspaceHooks';

export const useDataStoreFileDownload = () => {
  const organizationId = useOrganizationId();
  const workspaceId = useWorkspaceId();
  return useCallback(
    async (filePath, options = {}) =>
      await Api.Workspaces.downloadWorkspaceFile(organizationId, workspaceId, filePath, options),
    [organizationId, workspaceId]
  );
};

export const useDataStoreFileDelete = () => {
  const organizationId = useOrganizationId();
  const workspaceId = useWorkspaceId();

  return useCallback(
    async (filePath) => await Api.Workspaces.deleteWorkspaceFile(organizationId, workspaceId, filePath),
    [organizationId, workspaceId]
  );
};

export const useDataStoreListAllFiles = () => {
  const organizationId = useOrganizationId();
  const workspaceId = useWorkspaceId();
  return useCallback(
    async () => await Api.Workspaces.findAllWorkspaceFiles(organizationId, workspaceId),
    [organizationId, workspaceId]
  );
};

export const useStartRunner = () => {
  const organizationId = useOrganizationId();
  const workspaceId = useWorkspaceId();
  const { t } = useTranslation();
  const setApplicationErrorMessage = useSetApplicationErrorMessage();
  const { associatedRunners, id: currentDatasetId } = useCurrentDataset();

  const updateDataStoreAssociatedRunner = useUpdateDatastoreAssociatedRunners();
  const startRunner = useCallback(
    async (runnerType) => {
      Api.Runners.startRun(organizationId, workspaceId, associatedRunners[runnerType]?.id)
        .then(() => updateDataStoreAssociatedRunner(currentDatasetId))
        .catch((error) => {
          console.error(error);
          setApplicationErrorMessage(
            error,
            t('commoncomponents.datastore.errors.runnerStart', 'A problem occurred when starting runner.')
          );
        });
    },
    [
      associatedRunners,
      currentDatasetId,
      organizationId,
      workspaceId,
      updateDataStoreAssociatedRunner,
      t,
      setApplicationErrorMessage,
    ]
  );

  return startRunner;
};
